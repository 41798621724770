@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.premium-feature-locked {
  @include full-width();
  border-radius: 16px;
  border: 1px solid $border-color;
  padding: 20px 60px;

  .pendingText {
    color: #F5A65B;
    font-family: "Montserrat Alternates", sans-serif;
    font-size: 1.125rem;
  }

  .premium-feature-pending__icon{
    width: 35px;
    height: 35px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }


  .checkBackSoon {
    line-height: 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    font-family: "Montserrat Alternates", sans-serif;
    margin-bottom: 10px;
  }

  .title-container {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  h2 {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .premium-feature-locked__icon {
    width: 25px;
    height: 30px;
    background-image: url(../../../assets/svg/lock.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .premium-feature-locked__title {
    color: $danger-color;
    @include font-heading();
    @include font-size(18);

  }
}