@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.TenantApplication {
  @include full-box();
  padding-top: 40px;
  border: none;

  .processingHeading {
    color: #F5A65B;
  }

  .processingText {
    margin: 7px 10px 0 0;
  }

  .TenantApplication__container {
    margin-top: 40px;
    @include space-y(40px);
  }
   .btn--danger {
    display: block;
    margin: 20px 0px;
  }

}