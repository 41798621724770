@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.LandlordLanding {
  @include full-width();
  @include splash-padding();

  h1 {
    @include font-size(60);
    line-height: 120%;
    margin-bottom: 40px;
  }
  .subText {
    margin-bottom: 60px;
  }

  h4 {
    font-family: "Montserrat Alternates";
  }

  .servicesHeader {
    font-family: "Montserrat Alternates";
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 0 0 50px 0;
  }

  h5 {
    font-family: "Montserrat Alternates";
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;

    .goGreen {
      font-family: "Montserrat Alternates";
      color: #8CC947;
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 30px;
    }
  }

  .topGreenSemi {
    width: 85px;
    height: 5px;
    border: none;
    background: #8CC947;
    border-radius: 13px;
    align-self: flex-start;
    margin: 0 0 15px 0;
  }

  .btn-screening {
    margin-bottom: 40px;
    height: 40px;
    font-size: 16px;
  }
  .LandlordLanding__splash-img {
    @include full-width();
    transform: scale(1.2);
    position: relative;
    top: -40px;

    @include md {
      top: 0;
    }
  }

  .LandlordLanding__details-container {
    @include full-width();
    padding: 50px 50px 30px 50px;
    margin-top: -40px;
    border-radius: 16px;
    @include box-shadow();
  }

  .LandlordLanding__details {
    @include flex-row(space-between);
    flex-wrap: wrap;
    align-items: flex-start;
    @include sm {
      flex-direction: column;
    }
  }

  .LandlordLanding__details-item {
    width: calc(50% - 40px);
    height: auto;
    @include flex-row(flex-start, flex-start);
    margin-bottom: 40px;
    align-items: center;
    @include sm {
      width: calc(50% - 20px);
      margin-right: 0;
    }

    .LandlordLanding__details-item__svg {
      width: 60px;
      height: 60px;
      margin-right: 20px;
      @include sm {
        margin-right: 0;
        flex-direction: column;
      }
    }
  }
}
