@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.ApplicationPreview {
  contain: content;

  @include full-box();

  .ApplicationPreview__container {
    margin-top: 40px;
    @include space-y(40px);
  }

  .premium-feature {
    margin: 20px 0;
  }

  .processingHeading {
    color: #F5A65B;
  }

  .processingText {
    margin: 7px 10px 0 0;
  }

  .ApplicationPreview__coapplicants-container {
    border-radius: 16px;
    @include box-shadow();
    padding: 20px 20px 10px 20px;

    .ApplicationPreview__coapplicants {
      @include flex-row(flex-start);
      flex-wrap: wrap;

      .ApplicationPreview__coapplicant {
        margin-bottom: 10px;
        border-radius: 34px;
        padding: 0px 15px;
        border: 1px solid $border-color;
        line-height: 34px;

        &:not(:last-of-type){
          margin-right: 10px;
        }
      }
    }
  }

  .fixedButtonCont {
    margin: 40px 0 0 0;
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    z-index: 1;
    // These two are here in case we want to have a colored div "bar" as a background
    //box-shadow: 0px -5px 22px 0 rgba(0, 1, 5, 0.1);
    //background-color: $background-color;
    display: flex;
    align-items: center;
    padding: 0 66px 0 0;

    .floatingBtn {
      box-shadow: rgba(14, 30, 37, 0.12) 0 2px 4px 0, rgba(14, 30, 37, 0.32) 0 2px 16px 0;
    }
  }
}
